<script lang="ts" setup>
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const props = withDefaults(
	defineProps<{
		type: "link" | "button";
		title?: string;
		link: string;
		iconHtml?: string;
		iconLink?: string;
		active?: boolean;
		dataTid?: string;
	}>(),
	{ type: "link" }
);

const emit = defineEmits<{ (event: "clickTab", link: string): void }>();

const isSvgCode = (icon: string): boolean => icon.trim().startsWith("<svg");
const isSvgPath = (icon: string): boolean => icon.trim().endsWith(".svg");

const handleClick = () => {
	emit("clickTab", props.link);
};
</script>

<template>
	<div v-if="type === 'button'" :class="['tab', { 'tab-active': active }]" :data-tid="dataTid" @click="handleClick">
		<ASvg v-if="iconLink" :name="iconLink" class="icon" />
		<i v-else-if="iconHtml && isSvgCode(iconHtml)" class="icon" v-html="iconHtml" />
		<NuxtImg
			v-else-if="iconHtml && isSvgPath(iconHtml)"
			:src="`${baseImageUrl}${iconHtml}`"
			width="28"
			height="28"
			loading="lazy"
			alt="category"
			class="icon"
		/>
		<AText :size="12" class="title color-gray-200" :modifiers="['uppercase', 'bold']">{{ title }}</AText>
	</div>
	<NuxtLink v-else :to="link" :class="['tab', { 'tab-active': active }]" :data-tid="dataTid">
		<ASvg v-if="iconLink" :name="iconLink" class="icon" />
		<i v-else-if="iconHtml && isSvgCode(iconHtml)" class="icon" v-html="iconHtml" />
		<NuxtImg
			v-else-if="iconHtml && isSvgPath(iconHtml)"
			:src="`${baseImageUrl}${iconHtml}`"
			width="28"
			height="28"
			loading="lazy"
			alt="category"
			class="icon"
		/>
		<AText :size="12" class="title color-gray-200" :modifiers="['uppercase', 'bold']">{{ title }}</AText>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.tab {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	gap: 8px;
	height: 44px;
	padding: 8px 12px;
	border-radius: 4px;
	flex-shrink: 0;
	cursor: pointer;
	background: transparent;
	transition: all 0.3s;

	&.tab-active {
		background: var(--gray-950);
		pointer-events: none;

		.title {
			color: var(--gray-50);
		}
	}

	@include media-breakpoint-up(lg) {
		&:hover {
			background: var(--gray-700);
		}
	}

	.icon {
		display: inline-flex;
		width: 28px;
		height: 28px;
		font-size: 28px;

		&:deep(svg) {
			display: inline-block;
			vertical-align: middle;
			max-width: 28px;
			max-height: 28px;
		}
	}
}
</style>
